import axios, {
    type AxiosRequestConfig,
    type AxiosResponse,
    type CancelTokenSource,
} from "axios";
import { CacheService } from "./cache-service";
import { stringify } from "qs";
import { isEmpty, isUndefined, isNull } from "lodash";
import { Inject } from "../utils/inject";

export enum Method {
    get = "get",
    post = "post",
    delete = "delete",
    put = "put",
    request = "request",
    options = "options",
    head = "head",
    patch = "patch",
}

export const TOKEN = "TOKEN";

export abstract class HttpUtil {
    protected purlPrefix: string = "";
    protected pdefaultConfig: AxiosRequestConfig;
    @Inject(CacheService)
    protected cache!: CacheService;

    constructor() {
        this.pdefaultConfig = axios.defaults as any;
    }

    get defaultConfig(): AxiosRequestConfig {
        return this.pdefaultConfig;
    }

    public creatCancelTokenSource(): CancelTokenSource {
        return axios.CancelToken.source();
    }

    public async request(config: AxiosRequestConfig): Promise<any> {
        return this.resultFilter(axios.request(this.getMergeConfig(config)));
    }

    public async get(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        return this.resultFilter(
            axios.get(
                this.purlPrefix + this.stringifyQuery(url, query),
                this.getMergeConfig(config)
            )
        );
    }

    public async getFile(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig,
        needPrefix: boolean = true
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        const urlPrefix = needPrefix ? this.purlPrefix : "";
        return axios.get(
            urlPrefix + url + this.formatUrlParam(query),
            this.getMergeConfig(config)
        );
    }

    public async delete(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        return this.resultFilter(
            axios.delete(
                this.purlPrefix + url + this.formatUrlParam(query),
                this.getMergeConfig(config)
            )
        );
    }

    public async head(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        return this.resultFilter(
            axios.head(
                this.purlPrefix + url + this.formatUrlParam(query),
                this.getMergeConfig(config)
            )
        );
    }

    public async options(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        return this.resultFilter(
            axios.options(
                this.purlPrefix + url + this.formatUrlParam(query),
                this.getMergeConfig(config)
            )
        );
    }

    public async post(
        url: string,
        data?: any,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        const _data = this.mergeData(data);
        return this.resultFilter(
            axios.post(
                this.purlPrefix + url + this.formatUrlParam(query),
                _data,
                this.getMergeConfig(config, _data)
            )
        );
    }

    public async put(
        url: string,
        data?: any,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        const _data = this.mergeData(data);
        return this.resultFilter(
            axios.put(
                this.purlPrefix + url + this.formatUrlParam(query),
                _data,
                this.getMergeConfig(config, _data)
            )
        );
    }

    public async patch(
        url: string,
        data?: any,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<any> {
        await this.requestFilter(url, query, config);
        const _data = this.mergeData(data);
        return this.resultFilter(
            axios.patch(
                this.purlPrefix + url + this.formatUrlParam(query),
                _data,
                this.getMergeConfig(config, _data)
            )
        );
    }

    /**
     * @description 请求相应时会被执行的方法， 必须被实现， 且内部必须被执行catchError方法
     */
    protected abstract resultFilter(
        result: Promise<AxiosResponse<any>>
    ): Promise<any>;

    /**
     * @description 发送请求前会被执行的方法， 必须被实现
     */
    protected abstract requestFilter(
        url: string,
        query?: Dict<string>,
        config?: AxiosRequestConfig
    ): Promise<void>;

    private formatUrlParam(params?: Dict<string>): string {
        let urlParamStr = "";
        for (const index in params) {
            if (params[index]) {
                urlParamStr += `${index}=${params[index]}&`;
            }
        }
        if (urlParamStr) {
            return `?${urlParamStr.replace(/&$/, "")}`;
        } else {
            return urlParamStr;
        }
    }

    private stringifyQuery(url: string, params?: Dict<string>): string {
        if (!isEmpty(params)) {
            const query: Dict<any> = {};
            for (const key in params) {
                if (
                    !isUndefined(params[key]) &&
                    !isNull(params[key]) &&
                    params[key] !== ""
                ) {
                    query[key] = params[key];
                }
            }
            const reg = /[?]/;
            url += reg.test(url)
                ? `${stringify(query)}`
                : `?${stringify(query)}`;
        }
        return url;
    }

    /**
     * @description 请求默认配置合自定义配置的合并， 必须被实现
     */
    protected abstract getMergeConfig(
        config?: AxiosRequestConfig,
        data?: any
    ): AxiosRequestConfig;

    /**
     * @description 发送请求前合并业务数据与系统级数据变为统一数据结构， 必须被实现
     */
    protected abstract mergeData(data: Dict<any>): Dict<any>;

    /**
     * @description 当请求响应后捕捉请求体异常的抽象方法， 必须被实现， 可自行控制执行时机
     */
    protected abstract catchError(res: AxiosResponse<any>): void;
}
