import { Emitter } from "@common";
export const updateXFormHiddenEmitter = new Emitter<void>();
export const hiddenNameMap: Dict<Dict<string[]>> = {
    default: {},
};
export function updateXFormHidden(
    formName: string,
    hiddenNames: string[],
    namespace?: string
): void {
    if (namespace) {
        hiddenNameMap[namespace] = hiddenNameMap[namespace] || {};
        hiddenNameMap[namespace]![formName] = hiddenNames;
    } else {
        hiddenNameMap.default![formName] = hiddenNames;
    }
    updateXFormHiddenEmitter.commit();
}
