import { showDialog } from "@nutui/nutui";
import { createVNode } from "vue";

export async function userAccountDialog(titleInfo: string): Promise<string> {
    return await new Promise((resolve) => {
        const inputId = "temp_input_of_useraccount";
        showDialog({
            title: titleInfo,
            content: createVNode("input", {
                id: inputId,
                style: {
                    width: "100px",
                },
            }),
            beforeClose: (action) => {
                if (action === "ok") {
                    const input = document.querySelector(
                        "#" + inputId
                    ) as HTMLInputElement;
                    if (input.value.trim()) {
                        resolve(input.value);
                        return true;
                    }
                }
            },
            noCancelBtn: true,
        });
    });
}
export async function localLogin(
    loginCallBack: (userAccount: string) => Promise<void>
): Promise<boolean> {
    // TODO 非企业微信环境， 测试用的代码， UAT开始阶段将弃用
    const userAccount = await userAccountDialog("请输入8位员工号");
    if (userAccount) {
        try {
            await loginCallBack(userAccount);
            location.replace(location.href);
            return true;
        } catch (e) {
            return await localLogin(loginCallBack);
        }
    }
    return false;
}
