<script setup lang="ts">
import { reactive } from "vue";
import { showTabbarFlag } from "@components";
import { keepAliveChangeFlag } from "@router";

const themeVars = reactive({});
</script>

<template>
    <nut-config-provider
        :class="{ hidebar: !showTabbarFlag }"
        :theme-vars="themeVars"
    >
        <router-view v-slot="{ Component }">
            <keep-alive :key="keepAliveChangeFlag">
                <component
                    :is="Component"
                    :key="$route.name"
                    v-if="$route.meta.keepAlive"
                />
            </keep-alive>
            <component
                :is="Component"
                :key="$route.name"
                v-if="!$route.meta.keepAlive"
            />
        </router-view>
        <tabbar />
    </nut-config-provider>
</template>

<style scoped lang="scss">
.nut-theme- {
    padding: $main-top-bottom-padding $main-left-right-padding;
    max-height: $calc-tabbar-height;
    overflow: auto;
    /* 隐藏滚动条-Firefox */
    scrollbar-width: none;
    /* 隐藏滚动条-IE 10+ */
    -ms-overflow-style: none;
    /* 隐藏滚动条-Chrome Safari */
    ::-webkit-scrollbar {
        display: none;
    }
    &.hidebar {
        max-height: $calc-full-height;
    }
}
</style>
