import { Inject } from "@common";
import { HttpService } from "../http/http.service";
import type {
    HcpSegment,
    CurCycleAdjust,
    NonTargetInfo,
    Doctor,
    TargetHcp,
    PaginationParam,
    SegmentType,
    OptionItem,
    FilterList,
    SegmentParam,
} from "@schema";

export class ReviewService {
    @Inject(HttpService)
    private http!: HttpService;
    /**
     * @description MR获取当前客户分级汇总情况
     */
    public async getDefaultResultFromSurvey(): Promise<HcpSegment> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationSummary"
        );
    }

    /**
     * @description MR获取本期Cycle调整变化
     */
    public async getCurCycleAdjustChange(): Promise<CurCycleAdjust> {
        return await this.http.post(
            "/front/base/common/review/v1/getCurCycleAdjustChange"
        );
    }

    /**
     * @description MR获取产品非目标科室客户信息
     */
    public async getProductNonTargetRoomHcpInfo(): Promise<NonTargetInfo[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getProductNonTargetRoomHcpInfo"
        );
    }

    /**
     * @description MR获取客户分级明细
     */
    public async getHcpSegmentListByMr(): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByMR"
        );
    }

    /**
     * @description DM获取代表目标客户列表
     */
    public async getRepTargetHcpList(): Promise<TargetHcp[]> {
        return await this.http.post(
            "/front/base/common/mccp/v1/getRepTargetHcpList"
        );
    }

    /**
     * @description DM获取客户分级明细
     * @param segment 客户等级
     * @param positionId 岗位Id
     */
    public async getHcpSegmentListByDm(
        segment: SegmentType,
        positionId: string
    ): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByDM",
            { segment, positionId }
        );
    }

    /**
     * @description DM获取客户分级明细ByPage
     * @param param 分页及查询过滤参数
     */
    public async getHcpSegmentListByDmByPage(
        param?: PaginationParam<SegmentParam>
    ): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByDMByPage",
            param
        );
    }

    /**
     * @description 获取客户分级明细-医院列表
     */
    public async getDetailHcoList(): Promise<OptionItem[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListHcoList"
        );
    }

    /**
     * @description MR获取客户分级明细ByPage
     * @param param 分页及查询过滤参数
     */
    public async getHcpSegmentListByMrByPage(
        param: PaginationParam<any>
    ): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByMRByPage",
            param
        );
    }

    /**
     * @description MR获取客户分级明细-Filter
     */
    public async getProductListByMr(): Promise<FilterList> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByMRFilter"
        );
    }

    /**
     * @description DM获取客户分级明细-Filter
     */
    public async getProductListByDm(): Promise<FilterList> {
        return await this.http.post(
            "/front/base/common/review/v1/getHcpSegmentationDetailListByDMFilter"
        );
    }
}
