import type { FORM_TYPE_KEY } from "@constants";

export interface Choice {
    choiceValue: string;
    displayOrder: number;
}

export interface DefaultQuestion {
    id: string;
    name: string;
    question: string;
    type: string;
    category: string;
    helpText: string;
    isMultipleChoice: boolean;
    isMandatory: boolean;
    minValue: number;
    maxValue: number;
    maxLength: number;
    regex: string;
    regexErrorMessage: string;
    decimal: number;
    questionChoiceList: Choice[];
    defaultValue: string[];
    dependenceBranch: string;
}

export interface Answer {
    questionId: string; // 题目ID
    questionName: string; // 题目名称(题目编码)
    values: string[]; // 题目值
    isUsed: boolean; // 题目是否使用（隐藏的为不使用）
}
export interface SurveyResult {
    surveyTargetId: string; // ID
    surveyId: string; // 问卷ID
    hcpId: string; // 客户ID
    questionList: Answer[]; // 提交问卷答案
    formulas: unknown[]; // 跨题校验公式
}

export interface SurveyQuestionChoice {
    choiceValue: string; // 选项内容
    displayOrder: number; // 显示顺序
}

export interface DependenceBranch {
    name: string;
    result: string;
    hidden: string[];
}

export interface SurveyQuestion {
    category: string; // 问题分类
    decimal: number; // 小数点位数
    defaultValues: string[]; // 继承的默认答案
    dependenceBranch: DependenceBranch[]; // 隐藏题目分支设置
    helpText: string; // 理解帮助
    id: string; // 问题ID
    isMandatory: boolean; // 是否必填
    isMultipleChoice: boolean; // 是否多项选择
    maxLength: number; // 最大长度
    maxValue: number; // 最大数值
    minValue: number; // 最小数值
    name: string; // 问题编号
    question: string; // 问题内容
    questionChoiceList: SurveyQuestionChoice[]; // 问卷问题-选项
    regex: string; // 正则表达式验证规则
    regexErrorMessage: string; // 验证错误信息
    type: FORM_TYPE_KEY; // 问题类型
}

export interface SurveyQuestionCategory {
    category: string; // 分类名称
    surveyQuestionList: SurveyQuestion[]; // 问卷问题列表
}

export interface SurveyInfo {
    surveyId: string; // 问卷Id
    surveyName: string; // 问卷名称
    categoryQuestionList: SurveyQuestionCategory[]; // 问卷问题-分类
    formulas: unknown[]; // 跨题校验公式
}

export interface SubmitterInfo {
    lastSubmitDate: string; // 最后提交时间
    lastSubmitSubmitterId: string; // 最后提交人ID
    lastSubmitSubmitterName: string; // 最后提交人名称
    sfdcUserId: string; // 当前登录人岗位ID
}

export enum ButtonType {
    SUBMIT_BTN = "submitBtn",
    RETURN_BTN = "returnBtn",
}
