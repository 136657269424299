import "./style.scss";
import router from "@router";
import { createApp } from "vue";
import App from "./App.vue";
import { IconFont } from "@nutui/icons-vue";
import { installTrack } from "@common";
import { installWxSDK } from "@common";
import { installDirectives } from "@directives";

(async function () {
    try {
        await installTrack();
        //await installWxSDK();
    } finally {
        const app = createApp(App);
        app.use(router);
        app.use(IconFont);
        installDirectives(app);
        app.mount("#app");
    }
})();
