import { Provide } from "@common";
import { PersonService } from "@services";

const personSvc = Provide(PersonService);

export default {
    async mounted(el: HTMLElement): Promise<void> {
        if (((await personSvc.isReadonly()) && el.parentNode) || (await personSvc.isReadonlyLevel(el.id))) {
            el.style.setProperty("pointer-events", "none");
            el.style.setProperty("opacity", "0.3");
        }
    },
};
