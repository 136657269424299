import type { TargetApproveStatus } from "./review.schema";

export enum CycleStageCode {
    SurveyCollectionEndDate = "SurveyCollectionEndDate",
    RecollectedEndDate = "RecollectedEndDate",
}
export interface CycleResult {
    curCycle: string;
    curTime: string;
    cycleStageName: string;
    cycleInfoDateValueList: CycleItem[];
    nvsSpmCycleMonth: string;
}

export interface CycleItem {
    cycleStageCode: CycleStageCode | void;
    cycleStageDateTime: string;
    cycleStageDateTImeName: string;
    cycleStageName: string;
}

export interface MessageItem {
    title: string;
    body: string;
    receivedTime: string;
}

export interface UserSummary {
    targetHcpCount: number;
    teamAvgTargetHcpCount: number;
    agentPostMap: Dict<any>;
    mcCycleStartDate: string;
}
export interface UserCallCapacity {
    positionId: string;
    bcpCallCount: number;
    forecastCallCount: number;
    callRangeLevelEnum: string;
    forecastTargetCount: number;
    targetRangeLevelEnum: string;
    targetAccountRange:  Dict<any>;
}

export interface CloneTerritory {
    hcoName: string;
    hcpId: string;
    productItemList: ProductItemList[];
    targetDoctorCount: number;
    moreEmp: boolean;
    empRowSize: string|number;
}
export interface Territory {
    hcoName: string;
    hcpId: string;
    productItemList: ProductItemList[];
    targetDoctorCount: number;
}

export interface ProductItemList {
    productId: string;
    productName: string;
    coverageRate: number;
    empItemList: Seller[]
}
export interface Seller {
    positionId: string;
    empName: string;
    multipleRepHcpCount: number;
    multipleRepRate: number;
}

export interface ApproveParam {
    approveStatus: TargetApproveStatus;
    positionIdList: string[];
}

export interface SuggestionParam {
    suggestionList: string[];
    positionIdList: string[];
}

export enum Role {
    MR = "MR",
    DM = "DM",
    BU_FE = "BU FE",
}
export interface User {
    postId: string;
    roleCode: Role;
    originRoleCode?: Role;
    sfdcUserId: string;
    shortToken?: string;
    transmitEncryptionKey: string;
    userName: string;
    isRolePlay: boolean;
    canCollection: boolean;
    wx_access_token?: string;
    wx_refresh_token?: string;
}

export interface ConfigInfo {
    typeCode: string;
    label: string;
    value: string;
    name: string;
}
