import { render, type DirectiveBinding, createVNode } from "vue";
import HelpTips from "./index.vue";
import { isNull } from "lodash";
enum Position {
    left = "left",
    right = "right",
}
export default {
    async mounted(el: HTMLElement, bind: DirectiveBinding): Promise<void> {
        const value =
            bind.value instanceof Promise ? await bind.value : bind.value;
        if (value) {
            const isCustom = !isNull(el.getAttribute("is-custom"));
            const div = document.createElement("div");
            render(
                createVNode(HelpTips, {
                    code: isCustom ? void 0 : value,
                    customSuggestiveText: isCustom ? value : void 0,
                }),
                div
            );
            switch (el.getAttribute("insert-position")) {
                case Position.left:
                    el.insertBefore(div.children[0], el.childNodes[0]);
                    break;
                case Position.right:
                default:
                    el.appendChild(div.children[0]);
            }
        }
    },
};
