import {
    type Constructable,
    type IProviderConfigure,
    ProvideScopeKeys,
} from "./util.interface";
import { Provide } from "./provide";

interface Description {
    configurable: boolean;
    enumerable: string;
    initializer: any;
    writable: boolean;
}
export function Inject(
    staticClass: Constructable<any>,
    config: IProviderConfigure = {
        provideScope: ProvideScopeKeys.Root as "root",
    }
): (target: any, propertyName: string, description?: Description) => void {
    return (
        target: any,
        propertyName: string,
        description?: Description
    ): void => {
        const option = {
            configurable: false,
            enumerable: false,
            get() {
                return Provide(staticClass, config);
            },
        };
        Object.defineProperty(target, propertyName, option);
        description &&
            Object.defineProperty(description, "initializer", option);
    };
}
