
declare global {
    interface Window {
        wx?: any;
    }
}


/**
 * 安装WeCom三方依赖jwxwork for H5 跳回小程序
 */
function installWeComJwxWork(): Promise<void> {
    const jwxwork = document.createElement("script");
    jwxwork.src = `https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js`;
    jwxwork.referrerPolicy = "origin"
    jwxwork.crossOrigin = "anonymous"
    const scriptTags = document.getElementsByTagName("script")
    // last tag
    const s = scriptTags[scriptTags.length - 1] as HTMLScriptElement;
    return new Promise((resolve, reject) => {
        jwxwork.onload = resolve as any;
        jwxwork.onerror = reject;
        jwxwork.onabort = reject;
        (s.parentNode as any).insertBefore(jwxwork, s);
    });
}

/**
 * 安装WeCom三方依赖jweixin for H5 跳回小程序
 */
function installWeComJWeiXin(): Promise<void> {
    const jweixin = document.createElement("script");
    jweixin.referrerPolicy = "origin"
    jweixin.crossOrigin = "anonymous"
    jweixin.src = `https://res.wx.qq.com/open/js/jweixin-1.3.2.js`;
    const scriptTags = document.getElementsByTagName("script")
    // last tag
    const s = scriptTags[scriptTags.length - 1] as HTMLScriptElement;
    return new Promise((resolve, reject) => {
        jweixin.onload = resolve as any;
        jweixin.onerror = reject;
        jweixin.onabort = reject;
        (s.parentNode as any).insertBefore(jweixin, s);
    });
}

export function navigateBackMiniProgram(): void {
    // 返回小程序
    console.log("返回小程序 wx ==> ", window.wx)
    try {
        window.wx.miniProgram.navigateBack();
    } catch (e) {
        console.log("返回小程序失败")
        console.error(e)
    }
}



export async function installWxSDK(): Promise<void> {
    await installWeComJwxWork()
    await installWeComJWeiXin()
}
