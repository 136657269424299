export default [
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "首页",
            mainPage: true,
        },
        component: () => import("../views/dashboard/mr.vue"),
    },
    {
        path: "/client",
        name: "client",
        meta: {
            title: "候选目标客户",
            keepAlive: true,
            mainPage: true,
        },
        component: () => import("../views/client/mr.vue"),
    },
    {
        path: "/search",
        name: "search",
        meta: {
            title: "查询搜索",
            keepAlive: true,
        },
        component: () => import("../views/search/index.vue"),
    },
    {
        path: "/client-details",
        name: "clientDetails",
        meta: {
            title: "客户详情",
        },
        component: () => import("../views/client-details/index.vue"),
    },
    {
        path: "/question-survey",
        name: "question-survey",
        meta: {
            title: "问卷调查",
        },
        component: () => import("../views/question-survey/index.vue"),
    },
    {
        path: "/review",
        name: "review",
        meta: {
            title: "分级结果",
            mainPage: true,
        },
        component: () => import("../views/review/index.vue"),
    },
    {
        path: "/review-details",
        name: "reviewDetails",
        meta: {
            title: "客户分级明细",
            keepAlive: true,
        },
        component: () => import("../views/review-details/index.vue"),
    },
    {
        path: "/message",
        name: "message",
        meta: {
            title: "消息通知列表",
        },
        component: () => import("../views/message/index.vue"),
    },
];
