import Notify from "./index.vue";
import { createVNode, render } from "vue";
const div: HTMLDivElement = document.createElement("div");

export function showNotify(option: {
    type?: string;
    duration?: number;
    msg?: string;
    color?: string;
    background?: string;
    className?: string;
    position?: string;
}): Promise<void> {
    document.body.appendChild(div);
    render(createVNode(Notify, option), div);
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            render(null, div);
            resolve();
        }, ((option && option.duration) || 3000) + 1000);
    });
}
