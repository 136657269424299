import { isNaN, isNil, isNumber, isString, lowerCase } from "lodash";
import { nextTick } from "vue";

const hexDigits = import.meta.env.VITE_HEX_DIGITS;
export function uuid(length: number = 35): string {
    const s: string[] = [];
    for (let i = 0; i < length; i++) {
        s[i] = (hexDigits + lowerCase(hexDigits)).substr(
            Math.floor(Math.random() * 0x24),
            1
        );
    }
    return s.join("");
}
const dateFormatStr = "yyyy-MM-dd hh:mm:ss";
export function dateFormat(
    date: string | number | Date,
    fmt: string = dateFormatStr
): string {
    if (!date) {
        return "";
    }
    if (!(date instanceof Date)) {
        if (isString(date)) {
            date = Date.parse(
                date
                    .toString()
                    .replace(/-/g, "/")
                    .replace(/(\d+)T(\d+)/, "$1 $2")
            );
        }
        date = new Date(date);
    }
    const o: Dict<number> = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 24进制小时
        "H+": date.getHours() > 12 ? date.getHours() - 12 : date.getHours(), // 12进制小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt
            .toString()
            .replace(
                RegExp.$1,
                String(date.getFullYear()).substr(4 - RegExp.$1.length)
            );
    }
    for (const k in o) {
        if (isNumber(o[k]) && new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt
                .toString()
                .replace(
                    RegExp.$1,
                    (RegExp.$1.length === 1
                        ? o[k]
                        : `00${o[k]}`.substr(String(o[k]).length)) as string
                );
        }
    }
    return fmt;
}

export function formatPath(path: string): string {
    return import.meta.env.BASE_URL + (path?.replace(/^\//, "") || "");
}

export function timeout(timestamp?: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(async () => {
            await nextTick();
            resolve();
        }, timestamp);
    });
}

export function isVoid(val: any): boolean {
    return isNil(val) || val === "" || isNaN(val);
}

export function isEmpty(target: Dict<any>): boolean {
    for (const index in target) {
        if (!isVoid(target[index])) {
            return false;
        }
    }
    return true;
}
