import { isFunction, cloneDeep } from "lodash";

const COMMON_CACHE = "SPM_COMMON_CACHE";
const MSG = "message";

interface Listener {
    unlisten: () => void;
}

/**
 * @description 提供相同origin之间的数据读写， 消息发送能力， storage的生命周期与启用的storage相同， 支持localStorage与sessionStorage, 默认localStorage
 */
export class CacheService {
    private cacheMap!: Dict<string>;
    private NAMESPACE: string = COMMON_CACHE;
    private _storage!: Storage;
    private channel =
        window.BroadcastChannel && new window.BroadcastChannel(this.NAMESPACE);
    private channelListenerMap: Map<string, ((data: Dict<any>) => void)[]> =
        new Map();
    constructor() {
        this.storageInit();
        this.refreshCacheMap();
        this.channel?.addEventListener(MSG, this.listenHandler.bind(this));
    }

    set storage(val: Storage) {
        switch (val) {
            case localStorage:
                this.replaceStorge(localStorage);
                break;
            case sessionStorage:
                this.replaceStorge(sessionStorage);
                break;
            default:
                throw new Error(
                    'Set Storage is not support: need to set "localStorage" or "sessionStorage"'
                );
        }
    }

    public set(key: string, value: any): void {
        if (key) {
            this.cacheMap[key] = value;
            this._storage.setItem(
                this.NAMESPACE,
                JSON.stringify(this.cacheMap)
            );
        }
    }

    public get(key: string): any {
        this.refreshCacheMap();
        return this.cacheMap[key];
    }

    public remove(key: string): void {
        if (key) {
            delete this.cacheMap[key];
            this._storage.setItem(
                this.NAMESPACE,
                JSON.stringify(this.cacheMap)
            );
        }
    }

    public clear(): void {
        this.cacheMap = {};
        this._storage.removeItem(this.NAMESPACE);
    }

    public send(type: string, data: any): void {
        data = cloneDeep(data);
        this.channel?.postMessage({ type, data });
        this.listenHandler({ data: { type, data } });
    }

    public listen(type: string, cb: (data: any) => void): Listener {
        const listenerCbs = this.channelListenerMap.get(type) || [];
        if (!listenerCbs.includes(cb) && isFunction(cb)) {
            listenerCbs.push(cb);
        }
        this.channelListenerMap.set(type, listenerCbs);
        return {
            unlisten: () => {
                const cbs = this.channelListenerMap.get(type) || [];
                const index = cbs.findIndex((e) => e === cb);
                if (index > -1) {
                    cbs.splice(index, 1);
                }
                this.channelListenerMap.set(type, cbs);
            },
        };
    }

    private refreshCacheMap(): void {
        try {
            const valStr = this._storage.getItem(this.NAMESPACE);
            this.cacheMap = (valStr && JSON.parse(valStr)) || {};
        } catch (e) {
            this.cacheMap = {};
        }
    }

    private replaceStorge(storage: Storage): void {
        if (this.isValidStorage(storage)) {
            if (storage !== this._storage) {
                storage.setItem(this.NAMESPACE, JSON.stringify(this.cacheMap));
                this._storage.removeItem(this.NAMESPACE);
                this._storage = storage;
            }
        } else {
            throw new Error("The Storage is not support on current browser.");
        }
    }

    private storageInit(): void {
        if (this.isValidStorage(localStorage)) {
            this._storage = localStorage;
        } else if (this.isValidStorage(sessionStorage)) {
            this._storage = sessionStorage;
        } else {
            throw new Error("The Storage is not support on current browser.");
        }
    }

    private isValidStorage(storage: Storage): boolean {
        if (storage) {
            try {
                storage.setItem("storageTest", "value");
                if (storage.getItem("storageTest") !== "value") {
                    storage.removeItem("storageTest");
                    return false;
                }
                storage.removeItem("storageTest");
                return true;
            } catch (e) {
                return false;
            }
        } else {
            return false;
        }
    }

    private listenHandler(event: { data: { type: string; data: any } }): void {
        const payload = event.data;
        const cbs = this.channelListenerMap.get(payload.type) || [];
        cbs.forEach((cb: (data: any) => void) => cb(payload.data));
    }
}
