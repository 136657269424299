import { Provide } from "./provide";
import { CacheService } from "../util-services/cache-service";
import { isDate, isBoolean } from "lodash";

declare global {
    interface Window {
        _hmt?: any;
    }
}
const cache = Provide(CacheService);

export enum TrackAction {
    SPM_SWITCH_AGENT = "SPM_SWITCH_AGENT",
    SPM_MR_CLIENT_VIEW = "SPM_MR_CLIENT_VIEW",
    SPM_EDIT_SURVEY = "SPM_EDIT_SURVEY",
    SPM_ONE_CLICK_APPROVAL = "SPM_ONE_CLICK_APPROVAL",
    HOSPITAL_DETAILS_PV = "hospital_details_pv",
    NOT_UPDATE_SURVEY_CLICK = "NOT_UPDATE_SURVEY_CLICK",
}

export interface TrackOption {
    [key: string]: string | number | Date | boolean;
    action_desc: string;
}

const enableTracking = "production" === import.meta.env.VITE_MODE;
const API_KEY = import.meta.env.VITE_BAIDU_API_KEY;

window.addEventListener("beforeunload", clearTracking);
const clearList = [
    "_clearUserId",
    "_clearUserProperty",
    "_clearPageviewProperty",
    "_clearAutoEventTrackingProperty",
    "_clearSessionProperty",
];
function clearTracking(): void {
    if (enableTracking) {
        window._hmt = window._hmt || [];
        for (const key of clearList) {
            window._hmt.push([key, true]);
        }
    }
}

export function installTrack(): Promise<void> {
    if (enableTracking) {
        clearTracking();
        const user = cache.get("CURRENT_USER");
        window._hmt.push(["_setAccount", API_KEY]);
        window._hmt.push(["_setUserId", user?.userName]);
        window._hmt.push([
            "_setUserProperty",
            {
                is_spm: 1,
                role_code: user?.originRoleCode,
            },
        ]);
        const hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${API_KEY}`;
        const s = document.getElementsByTagName(
            "script"
        )[0] as HTMLScriptElement;
        return new Promise((resolve, reject) => {
            hm.onload = resolve as any;
            hm.onerror = reject;
            hm.onabort = reject;
            (s.parentNode as any).insertBefore(hm, s);
        });
    } else {
        return Promise.resolve();
    }
}

export function manualTracking(type: TrackAction, option: TrackOption): void {
    if (enableTracking) {
        for (const key in option) {
            if (isDate(option[key])) {
                option[key] = Math.round(
                    (option[key] as Date).getTime() / 10 ** 3
                );
            } else if (isBoolean(option[key])) {
                option[key] = option[key] ? 1 : 0;
            }
        }
        /**
         * @属性值类型说明
         * @属性值共有4种类型 Text、Number、Date、Boolean。
         * @Text	String	"BaiduAnalytics"	转义后最大长度不能超过 256 个字符
         * @Number	Number	66 或者 66.0	数字精度可以在分析云中进行调整，最大可支持到小数点后 7 位
         * @Date	Number	1606780800	JavaScript 中通过 Date 获取到的时间戳是精确到毫秒的，而分析云的时间类型是需要精确到秒的时间戳
         * @Boolean	Number	1 或 0	这里只支持传入 1 或 0，1 代表是，0 代表否
         **/
        window._hmt.push([
            "_trackCustomEvent",
            type,
            {
                action_date: Math.round(Date.now() / 10 ** 3),
                ...option,
            },
        ]);
    }
}
