import { Inject } from "@common";
import { HttpService } from "../http/http.service";
import type {
    Hospital,
    HospitalDetail,
    HospitalParam,
    PaginationParam,
} from "@schema";

export class HospitalService {
    @Inject(HttpService)
    private http!: HttpService;
    /**
     * @description DM查询医院列表
     * @param param 分页及查询过滤参数
     */
    public async getHospitalList(): Promise<Hospital[]> {
        return await this.http.post("/front/base/common/hco/v1/dmQueryHco");
    }

    /**
     * @description 获取候选目标客户列表ByPage
     * @param param 分页及查询过滤参数
     */
    public async getHospitalListByPage(
        param?: PaginationParam<HospitalParam>
    ): Promise<Hospital[]> {
        return await this.http.post(
            "/front/base/common/hco/v1/dmQueryHcoByPage",
            param
        );
    }

    /**
     * @description DM获取医院详细信息
     * @param hcoId 医院ID
     * @param mrPositionId 岗位ID
     */
    public async getHospitalDetail(
        hcoId: string,
        mrPositionId?: string
    ): Promise<HospitalDetail> {
        return await this.http.post("/front/base/common/hco/v1/getHcoDetail", {
            hcoId,
            mrPositionId,
        });
    }
}
