import { isUndefined } from "lodash";
import {
    type Constructable,
    type IProviderConfigure,
    ProvideScopeKeys,
    MICRO_FRONT_END_MAIN,
} from "./util.interface";
declare global {
    interface Window {
        global?: any;
        mountedApp?: string;
    }
}

const instanceMap: Map<Constructable<any>, any> = new Map<
    Constructable<any>,
    any
>();

const AppList: Dict<Map<Constructable<any>, any>> = {};

function clearDirty(instance: any): void {
    for (const key in instance) {
        if (
            isUndefined(instance[key]) &&
            // eslint-disable-next-line no-prototype-builtins
            instance.hasOwnProperty(key) &&
            !isUndefined(instance.__proto__)
        ) {
            delete instance[key];
        }
    }
}

function createSingletonInstance<T>(serviceClass: Constructable<T>): T {
    const singletonIns = singleton(serviceClass);
    return new singletonIns();
}
function SelfProvide<T>(
    serviceClass: Constructable<T>,
    specAppName?: string
): T {
    const appName = specAppName || window.mountedApp;
    if (!appName) {
        throw new Error("AppName should be defined.");
    }
    let target = AppList[appName];
    if (!target) {
        target = AppList[appName] = new Map<
            Constructable<any>,
            new (...args: any) => any
        >();
    }
    let instance = target.get(serviceClass);
    if (instance) {
        return instance;
    } else {
        instance = createSingletonInstance(serviceClass);
        clearDirty(instance);
        target.set(serviceClass, instance as new (...args: any) => any);
    }
    return instance;
}
function RootProvide<T>(serviceClass: Constructable<T>): T {
    let instance = instanceMap.get(serviceClass);
    if (instance) {
        return instance;
    } else {
        instance = createSingletonInstance(serviceClass);
        clearDirty(instance);
        instanceMap.set(serviceClass, instance as new (...args: any) => any);
    }
    return instance;
}

export function Provide<T>(
    serviceClass: Constructable<T>,
    config: IProviderConfigure = {
        provideScope: ProvideScopeKeys.Root as "root",
    }
): T {
    switch (config && config.provideScope) {
        case ProvideScopeKeys.Root:
            return RootProvide(serviceClass);
        case ProvideScopeKeys.Self:
            return SelfProvide(serviceClass);
        case ProvideScopeKeys.Main:
            return SelfProvide(serviceClass, MICRO_FRONT_END_MAIN);
        default:
            if (config.namespace) {
                return SelfProvide(serviceClass, config.namespace);
            } else {
                return RootProvide(serviceClass);
            }
    }
}

export function singleton<T>(serviceClass: Constructable<T>): Constructable<T> {
    let ins: any;
    const proxy = new Proxy(serviceClass, {
        construct(target, argArray, newTarget) {
            if (!ins) {
                ins = Reflect.construct(target, argArray, newTarget);
            }
            return ins;
        },
    });
    serviceClass.prototype.constructor = proxy;
    return proxy;
}
