import { formatPath } from "@common";
import { Role } from "@schema";

export const TAB_ITEM_LIST = {
    [Role.MR]: [
        {
            title: "首页",
            activeIcon: formatPath("icon/home_active.svg"),
            icon: formatPath("icon/home.svg"),
            name: "dashboard",
            url: "/",
        },
        {
            title: "候选目标客户",
            activeIcon: formatPath("icon/client_active.svg"),
            icon: formatPath("icon/client.svg"),
            name: "client",
            url: "/client",
        },
        {
            title: "分级结果",
            activeIcon: formatPath("icon/review_active.svg"),
            icon: formatPath("icon/review.svg"),
            name: "review",
            url: "/review",
        },
    ],
    [Role.DM]: [
        {
            title: "首页",
            activeIcon: formatPath("icon/home_active.svg"),
            icon: formatPath("icon/home.svg"),
            name: "dashboard",
            url: "/",
        },
        {
            title: "目标医院",
            activeIcon: formatPath("icon/hospital_active.svg"),
            icon: formatPath("icon/hospital.svg"),
            name: "hospital",
            url: "/hospital",
        },
        {
            title: "目标客户",
            activeIcon: formatPath("icon/client_active.svg"),
            icon: formatPath("icon/client.svg"),
            name: "client",
            url: "/client",
        },
    ],
    [Role.BU_FE]: [],
};
