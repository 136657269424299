<template>
    <nut-notify v-model:visible="show" v-bind="props" />
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
const show = ref(false);
const props = defineProps({
    type: String,
    duration: {
        type: Number,
        default: 3000,
    },
    msg: String,
    color: String,
    background: String,
    className: String,
    position: String,
});
onMounted(() => (show.value = true));
</script>
