import type { Subscription } from 'rxjs';

export class Listener {
  private subscription: Subscription;
  constructor(subscription?: Subscription) {
      this.subscription = subscription as Subscription;
  }
  public unlisten(): void {
      this.subscription.unsubscribe();
  }
}
