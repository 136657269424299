export default [
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "首页",
            mainPage: true,
        },
        component: () => import("../views/dashboard/dm.vue"),
    },
    {
        path: "/hospital",
        name: "hospital",
        meta: {
            title: "目标医院",
            keepAlive: true,
            mainPage: true,
        },
        component: () => import("../views/hospital/index.vue"),
    },
    {
        path: "/hospital-details",
        name: "hospital-details",
        meta: {
            title: "医院详情",
        },
        component: () => import("../views/hospital-details/index.vue"),
    },
    {
        path: "/search",
        name: "search",
        meta: {
            title: "查询搜索",
            keepAlive: true,
        },
        component: () => import("../views/search/index.vue"),
    },
    {
        path: "/client",
        name: "client",
        meta: {
            title: "目标客户",
            keepAlive: true,
            mainPage: true,
        },
        component: () => import("../views/client/dm.vue"),
    },
    {
        path: "/clientEMP",
        name: "clientEMP",
        meta: {
            title: "同区域多代表覆盖医生列表",
            keepAlive: true,
        },
        component: () => import("../views/client-emp/index.vue"),
    },
];
