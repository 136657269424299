import { Inject } from "@common";
import { HttpService } from "../http/http.service";
import type {
    SurveyInfo,
    SurveyQuestion,
    SurveyQuestionCategory,
    SurveyResult,
    SubmitterInfo,
} from "@schema";
import { ButtonType } from "@schema";
import { showDialog } from "@nutui/nutui";
import { showNotify } from "@components";
import { IGNORE_SUBMITTER } from "@constants";

export class SurveyService {
    @Inject(HttpService)
    private http!: HttpService;

    /**
     * @description 获取问卷详细信息&默认答案
     * @param surveyId 问卷 ID
     * @param hcpId HCP ID
     */
    public async getDefaultResultFromSurvey(
        hcpId: string,
        surveyId: string
    ): Promise<SurveyInfo> {
        const res = await this.http.post(
            "/front/base/common/survey/v1/getSurveyInfoAndDefaultResult",
            {
                hcpId,
                surveyId,
            }
        );
        res?.categoryQuestionList?.forEach(
            (categoryItem: SurveyQuestionCategory) => {
                categoryItem.surveyQuestionList.forEach(
                    (surveyItem: SurveyQuestion) => {
                        // 处理一下 返回的 dependenceBranch 数据结构
                        try {
                            surveyItem.dependenceBranch = JSON.parse(
                                surveyItem.dependenceBranch as any
                            );
                        } catch (e) {
                            surveyItem.dependenceBranch = [];
                        }
                    }
                );
            }
        );
        return res;
    }

    /**
     * @description 提交问卷
     * @param param 提交表单参数
     * @param btnType 按钮类型
     */
    public async submitSurvey(
        param: SurveyResult,
        btnType: string
    ): Promise<void> {
        const notifyMsg =
            btnType === ButtonType.SUBMIT_BTN ? "提交成功!" : "保存成功!";
        await this.http.post(
            "/front/base/common/survey/v1/submitSurvey",
            param
        );
        showNotify({
            type: "success",
            msg: notifyMsg,
        });
    }

    /**
     * @description 检查问卷验证, 进行跨题校验
     * @param param 提交表单参数
     */
    public async checkSurveyResult(param: SurveyResult): Promise<void> {
        if (param.formulas) {
            const resultRes = await this.http.post(
                "/front/base/common/survey/v1/checkSurveyResult",
                param
            );
            if (resultRes) {
                const content = `跨题校验错误: ${resultRes}`;
                showDialog({
                    title: "提示",
                    content,
                    textAlign: "left",
                    noOkBtn: true,
                    cancelText: "返回",
                });
                throw new Error(content);
            }
        }
    }

    /**
     * @description 获取问卷默认答案
     * @param questionList 问题列表
     * @desc 根据默isMultipleChoice defaultValue 设置初始默认值
     */
    public getQuestionDefaultData(questionList: SurveyQuestion[]): Dict<any> {
        const res: Dict<any> = {};
        for (const item of questionList) {
            res[item.name] =
                (item.defaultValues &&
                    item.defaultValues.length &&
                    (item.isMultipleChoice
                        ? item.defaultValues
                        : item.defaultValues[0])) ||
                (item.isMultipleChoice ? [] : "");
        }
        return res;
    }

    /**
     * @description 校验提交人信息并提交问卷
     * @param submitterInfo 问卷提交人信息
     * @param btnType 调用按钮类型
     * @return dialog content 文案
     * @desc 根据问卷上一个提交信息, 如果本次修改时间与上一个提交时间同月 不同提交人, 显示是否覆盖答案content
     * @desc 如果不满足以上条件, 显示"是否确认提交问卷"
     */
    public checkLastSubmitter(
        submitterInfo: SubmitterInfo,
        btnType: ButtonType
    ): Promise<void> {
        if (
            submitterInfo.lastSubmitDate &&
            submitterInfo.lastSubmitSubmitterName !== IGNORE_SUBMITTER
        ) {
            const nowMonth = new Date().getMonth() + 1;
            const lastSubmitMonth =
                new Date(submitterInfo.lastSubmitDate).getMonth() + 1;
            return new Promise((resolve, reject) => {
                const isOverride =
                    nowMonth === lastSubmitMonth &&
                    submitterInfo.sfdcUserId !==
                        submitterInfo.lastSubmitSubmitterId;
                let content = "是否确认提交问卷?";
                if (isOverride) {
                    content = `该问卷已在${submitterInfo.lastSubmitDate}时间被${submitterInfo.lastSubmitSubmitterName}提交, 是否覆盖问卷答案?`;
                }
                if (isOverride || btnType === ButtonType.SUBMIT_BTN) {
                    showDialog({
                        title: "提示",
                        content,
                        textAlign: isOverride ? "left" : "center",
                        okText: "确定",
                        onOk: resolve,
                        onCancel: reject,
                    });
                } else {
                    resolve();
                }
            });
        } else {
            return Promise.resolve();
        }
    }
}
