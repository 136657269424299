import { Inject } from "@common";
import { HttpService } from "../http/http.service";
import type {
    Doctor,
    SurveySummary,
    SurveySegment,
    OptionItem,
    Affiliation,
    PaginationParam,
    DocterParam,
    DocterCoverageParam,
    Pageinfo,
} from "@schema";
import { showDialog } from "@nutui/nutui";
import { showNotify } from "@components";
import { createVNode } from "vue";

export class DoctorService {
    @Inject(HttpService)
    private http!: HttpService;
    public selectedAffiliationList?: Affiliation[];
    private _filterHcpIdList?: string[];
    private _filterTags?: string[];

    get filterHcpIdList(): string[] | undefined {
        return this._filterHcpIdList;
    }

    get filterTags(): string[] | undefined {
        return this._filterTags;
    }
    /**
     * @description 创建新的客户客户
     * @param hcpId hcp ID
     */
    public async createNewDoctor(hcpId: string): Promise<void> {
        await this.http.post(
            "/front/base/common/surveyDoctor/v1/addSurveyDoctor",
            hcpId
        );
        showNotify({
            type: "success",
            msg: "添加成功!",
        });
    }

    /**
     * @description 删除较低客户(软删除)
     * @param doctorIds 客户ID集合
     */
    public deleteLowAlertDoctor(doctorIds: string[],lowThreshold:number,hightThreshold:number): Promise<void> {
        return new Promise((resolve, reject) => {
            const div1 = createVNode("div", null, "当前候预计目标医生数已较低，删除该问卷会导致该候选目标医生被删除，建议不要删除该问卷。");
            const div2 = createVNode("div", null, `(建议目标医生数: ${lowThreshold}~${hightThreshold})`);
            showDialog({
                title: "确认删除吗？",
                content: createVNode(
                    "div",
                    {
                        style: {
                            fontSize: "14px",
                            fontWeight: "600",
                        },
                    },
                   [div1, div2]
                ),
                textAlign: "left",
                okText: "确定",
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/surveyDoctor/v1/deleteSurveyDoctor",
                        doctorIds
                    );
                    showNotify({
                        type: "success",
                        msg: "删除成功!",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 删除客户客户(软删除)
     * @param doctorIds 客户ID集合
     */
    public deleteDoctor(doctorIds: string[]): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                content: createVNode(
                    "div",
                    {
                        style: {
                            fontSize: "20px",
                            fontWeight: "600",
                        },
                    },
                    "确认删除吗?"
                ),
                okText: "确定",
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/surveyDoctor/v1/deleteSurveyDoctor",
                        doctorIds
                    );
                    showNotify({
                        type: "success",
                        msg: "删除成功!",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 获取客户问卷概览
     */
    public async getDoctorSurveySummary(): Promise<SurveySummary> {
        const res = (await this.http.post(
            "/front/base/common/surveyDoctor/v1/getDoctorSurveySummary"
        )) as SurveySummary;
        return res;
    }

    /**
     * @description 获取候选目标客户列表
     * @param doctorId 客户ID
     */
    public async getSurveyDoctorList(doctorId?: string): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/getSurveyDoctorList",
            doctorId
        );
    }

    /**
     * @description 获取候选目标客户列表ByPage
     * @param param 分页及查询过滤参数
     */
    public async getSurveyDoctorListByPage(
        param?: PaginationParam<DocterParam>
    ): Promise<Pageinfo> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/getSurveyDoctorListByPage",
            param
        );
    }

    /**
     * @description 获取候选目标客户列表ByPage
     * @param param 分页及查询过滤参数
     */
    public async getSurveyDoctorListByPageForDM(
        param?: PaginationParam<DocterCoverageParam>
    ): Promise<Pageinfo> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/getSurveyDoctorListByPageForDM",
            param
        );
    }

    /**
     * @description 获取候选目标客户-医院列表
     */
    public async getHcoList(): Promise<OptionItem[]> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/getSurveyDoctorListHcoList"
        );
    }

    /**
     * @description 客户搜索
     * @param queryString 搜索条件
     */
    public async queryHcp(queryString: string): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/queryHcp",
            queryString
        );
    }

    /**
     * @description 搜索客户ByPage
     * @param param 分页及查询过滤参数
     */
    public async queryHcpByPage(
        param: PaginationParam<any>
    ): Promise<Doctor[]> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/queryHcpByPage",
            param
        );
    }

    /**
     * @description 获取候选目标客户详细信息(客户问卷及分级)
     * @param hcpId HCP ID
     */
    public async getSegment(hcpId?: string): Promise<SurveySegment[]> {
        return await this.http.post(
            "/front/base/common/surveyDoctor/v1/getDoctorSurveyAndSegment",
            hcpId
        );
    }

    /**
     * @description 获取产品组内的产品
     * @param hcpId HCP ID
     */
    public async getProductsByGroupId(
        groupId: string
    ): Promise<{ externalId: string; id: string; name: string }[]> {
        return await this.http.post(
            "/front/base/common/product/v1/getProductInfoByProductGroupId",
            groupId
        );
    }

    /**
     * @description 删除候选目标客户的问卷
     * @param id 问卷ID
     */
    public deleteSurvey(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                content: createVNode(
                    "div",
                    {
                        style: {
                            fontSize: "20px",
                            fontWeight: "600",
                        },
                    },
                    "确认删除该问卷吗?"
                ),
                okText: "确定",
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/surveyDoctor/v1/deleteSurveyDoctorSurvey",
                        id
                    );
                    showNotify({
                        type: "success",
                        msg: "删除成功!",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }
    /**
     * @description 较低时-删除候选目标客户的问卷
     * @param id 问卷ID
     */
    public deleteSurveyOnly(id: string,lowThreshold: number,hightThreshold: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const div1 = 
            createVNode("div", null, "当前候预计目标医生数已较低，删除该问卷会导致该候选目标医生被删除，建议不要删除该问卷。") 

            const div2 = createVNode("div", null, `(建议目标医生数: ${lowThreshold}~${hightThreshold})`);
            showDialog({
                content: createVNode(
                    "div",
                    {
                        style: {
                            fontSize: "14px",
                            fontWeight: "600",
                        },
                    },
                   [div1, div2]
                ),
                textAlign: "left",
                okText: "确定",
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/surveyDoctor/v1/deleteSurveyDoctorSurvey",
                        id
                    );
                    showNotify({
                        type: "success",
                        msg: "删除成功!",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 添加候选目标客户的问卷
     * @param id 问卷ID
     */
    public addSurvey(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                content: createVNode(
                    "div",
                    {
                        style: {
                            fontSize: "20px",
                            fontWeight: "600",
                        },
                    },
                    "确认添加该问卷吗?"
                ),
                okText: "确定",
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/surveyDoctor/v1/addSurveyDoctorSurvey",
                        id
                    );
                    showNotify({
                        type: "success",
                        msg: "添加成功!",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    public setHcpFilterStatus(ids: string[], tags: string[]): void {
        this._filterHcpIdList = ids;
        this._filterTags = tags;
    }
    public clearHcpFilterStatus(): void {
        delete this._filterHcpIdList;
        delete this._filterTags;
    }
}
