import { uuid } from "@common";
import { render, type DirectiveBinding, createVNode, ref } from "vue";
import Popup from "./index.vue";
import AlloyFinger from "alloyfinger";
import { forEach } from "lodash";

const div: HTMLDivElement = document.createElement("div");
export const show = ref(false);
const afMap = new Map();
function preventDefault(e: Event): void {
    e.cancelable && e.preventDefault();
}
const checkItems = [
    "overflow",
    "text-overflow",
    "width",
    "max-width",
    "min-width",
];
const overflowStyles = {
    "text-overflow": "ellipsis",
    overflow: "hidden",
    "word-break": "keep-all",
    "white-space": "nowrap",
};
const nonHiddenDisplays = ["inline", "flex", "inline-flex"];
function isOverflow(el: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
        setTimeout(() => {
            const originMap = new Map<string, string>();
            checkItems.forEach((v) =>
                originMap.set(v, el.style.getPropertyValue(v))
            );
            checkItems.forEach((v) => {
                if (v === "width") {
                    el.style.setProperty(v, "max-content");
                } else {
                    el.style.setProperty(v, "initial");
                }
            });
            const width = el.clientWidth;
            originMap.forEach((v, k) =>
                v ? el.style.setProperty(k, v) : el.style.removeProperty(k)
            );

            const res = width - el.clientWidth >= 7;
            res
                ? forEach(overflowStyles, (value, key) =>
                      el.style.setProperty(key, value)
                  )
                : el.style.setProperty("overflow", "visible");
            resolve(res);
        });
    });
}
export default {
    async mounted(el: HTMLElement, binding: DirectiveBinding): Promise<void> {
        const text = binding.value || el.innerText;
        if (!binding.value && el.children.length > 0 && text?.trim()) {
            return;
        }
        if (
            nonHiddenDisplays.includes(
                getComputedStyle(el).getPropertyValue("display")
            )
        ) {
            el.style.setProperty("display", "inline-block");
        }
        if (text?.trim() && (await isOverflow(el))) {
            document.body.appendChild(div);
            el.addEventListener("touchstart", preventDefault, false);
            el.addEventListener("selectstart", preventDefault);
            el.addEventListener("click", preventDefault);
            afMap.set(
                el,
                new AlloyFinger(el, {
                    tap() {
                        const _uuid = uuid(8);
                        el.setAttribute("id", _uuid);
                        const color =
                            el.getAttribute("popup-text-color") ||
                            getComputedStyle(el).getPropertyValue("color");
                        const fontWeight =
                            el.getAttribute("popup-text-weight") ||
                            getComputedStyle(el).getPropertyValue(
                                "font-weight"
                            );
                        const fontSize =
                            el.getAttribute("popup-text-size") ||
                            getComputedStyle(el).getPropertyValue("font-size");
                        render(
                            createVNode(Popup, {
                                color,
                                fontWeight,
                                fontSize,
                                text,
                                teleport: `#${_uuid}`,
                                teleportElement: el,
                            }),
                            div
                        );
                        show.value = true;
                        const overlay = div.querySelector("view.nut-overlay");
                        const popup = div.querySelector("view.nut-popup");
                        function close() {
                            show.value = false;
                            render(null, div);
                            overlay?.removeEventListener("click", close);
                            popup?.removeEventListener("click", close);
                        }

                        overlay?.addEventListener("click", close);
                        popup?.addEventListener("click", close);
                    },
                })
            );
        }
    },
    async unmounted(el: HTMLElement, binding: DirectiveBinding): Promise<void> {
        if ((binding.value || el.innerText)?.trim() && (await isOverflow(el))) {
            const af = afMap.get(el);
            el.removeEventListener("touchstart", preventDefault, false);
            el.removeEventListener("selectstart", preventDefault);
            el.removeEventListener("click", preventDefault);
            af?.destroy();
        }
    },
};
