export type Constructable<T> = new (...args: any) => T;
export const MICRO_FRONT_END_MAIN = 'MICRO-FRONT-END-MAIN';
export const ProvideScopeKeys = {
  Root: 'root',
  Self: 'self',
  Main: 'main'
};

export interface IProviderConfigure {
  provideScope?: 'root' | 'self' | 'main';
  namespace?: string;
}
