import type { FORM_TYPE_KEY } from "@constants";

export * from "./person.schema";
export * from "./hospital.schema";
export * from "./survey.schema";
export * from "./doctor.schema";
export * from "./http.schema";
export * from "./review.schema";
export interface PaginationParam<T> {
    pageNum: number;
    pageSize: number;
    data?: T;
    from?: string;
    offset?: number;
    limit?: number;
}

export interface FormItem {
    name: string;
    required?: boolean;
    type: FORM_TYPE_KEY;
    label: string;
    maxLength?: number;
    minLength?: number;
    maxValue?: number;
    minValue?: number;
    decimal?: number;
    regex?: string;
    regexErrorMessage?: string;
    isMultiple?: boolean;
    helpText: string;
    optionItems: { value: any }[];
    dependence?: any;
    keyboardVisible?: boolean;
}

export enum SortType {
    asc = "asc",
    desc = "desc",
}

export enum TouchMoveDirection {
    left = "Left",
    right = "Right",
}
