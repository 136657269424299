import { Observable, Subject } from "rxjs";
import { Listener } from "./Listener";

export class Emitter<T> {
    private emitter: Subject<T>;

    constructor() {
        this.emitter = new Subject<T>();
    }

    public commit(message?: T): void {
        this.emitter.next(message);
    }

    public listen(cb: (message: T) => void): Listener {
        return new Listener(this.emitter.subscribe(cb));
    }

    public unlisten(): void {
        this.emitter.unsubscribe();
    }

    public getPromise(): Promise<T> {
        return this.emitter.toPromise();
    }

    public getObservable(): Observable<T> {
        return this.emitter;
    }

    public close(): void {
        this.emitter.complete();
    }
}
