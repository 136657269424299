import { ref } from "vue";

export const isAgent = ref(false);
export const showTabbarFlag = ref(true);

export function showTabbar(): void {
    showTabbarFlag.value = true;
}
export function hideTabbar(): void {
    showTabbarFlag.value = false;
}
