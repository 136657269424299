import type { App, Directive } from "vue";
import popup from "./popup";
import helpTips from "./help-tips";
import restricted from "./restricted";
import { kebabCase } from "lodash";

const directiveCbs: Dict<Directive> = {
    popup,
    restricted,
    helpTips,
};
export function installDirectives(app: App<Element>): void {
    for (const key in directiveCbs) {
        app.directive(kebabCase(key), directiveCbs[key] as Directive);
    }
}
