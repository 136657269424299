import {
    createRouter,
    createWebHistory,
    type RouteLocationNormalized,
    type Router,
} from "vue-router";
import MRRoutes from "./mr";
import DMRoutes from "./dm";
import { isIOS, Provide } from "@common";
import { PersonService, HttpService } from "@services";
import { Role, TouchMoveDirection } from "@schema";
import AlloyFinger from "alloyfinger";
import { TAB_ITEM_LIST } from "@components/tabbar/config";
import { ref } from "vue";

const personSvc = Provide(PersonService);
const http = Provide(HttpService);
const currentRole = personSvc.currentUser?.roleCode;

let router!: Router;
switch (currentRole) {
    case Role.MR:
        router = createRouter({
            history: createWebHistory(import.meta.env.BASE_URL),
            routes: MRRoutes,
        });
        break;
    case Role.DM:
        router = createRouter({
            history: createWebHistory(import.meta.env.BASE_URL),
            routes: DMRoutes,
        });
        break;
    default:
        http.doLogin();
        throw new Error("未知的用户角色");
}

router.afterEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (to.meta.mainPage && (from.meta.mainPage || !to.meta.keepAlive)) {
            refreshKeepAlive();
        }
        document.title = (to.meta.title as string) || "";
    }
);

export const disableSwiper = ref(false);
if (!isIOS) {
    const ignorePaths = TAB_ITEM_LIST[personSvc.currentUser.roleCode].map(
        (e) => e.url
    );
    new AlloyFinger(document.body, {
        swipe(evt: TouchEvent & { direction: TouchMoveDirection }) {
            if (
                !disableSwiper.value &&
                !ignorePaths.includes(location.pathname)
            ) {
                switch (evt.direction) {
                    case TouchMoveDirection.left:
                        router.go(1);
                        break;
                    case TouchMoveDirection.right:
                        router.go(-1);
                        break;
                }
            }
        },
    });
}

export const keepAliveChangeFlag = ref(0);
export function refreshKeepAlive(): void {
    keepAliveChangeFlag.value++;
}

export default router;
