<template>
    <img
        v-if="props.code || props.customSuggestiveText"
        class="help-tips"
        src="@assets/img/help-tips.svg"
        @click="showHelpText"
        @touchend="showHelpText"
    />
</template>
<script setup lang="ts">
import { Provide } from "@common";
import { PersonService } from "@services";
import { HELP_TEXT } from "@constants";
import { throttle } from "lodash";
import { showDialog } from "@nutui/nutui";

const personSvc = Provide(PersonService);
const props = defineProps({
    code: String,
    customSuggestiveText: String,
});
const showHelpText: (e: TouchEvent | MouseEvent) => void = throttle(
    (e: TouchEvent | MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (!props.customSuggestiveText && props.code) {
            personSvc.popupSuggestiveText(HELP_TEXT, props.code);
        } else if (props.customSuggestiveText && !props.code) {
            showDialog({
                title: "温馨提示",
                content: props.customSuggestiveText,
                textAlign: "left",
                noCancelBtn: true,
                popClass: "popClass",
            });
        }
    },
    1000,
    {
        trailing: false,
    }
);
</script>
<style scoped lang="scss">
img {
    &.help-tips {
        margin: 0 3px 0 3px;
        vertical-align: baseline;
    }
}
</style>
